/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        AOS.init({
          duration: 1200,
          disable: "mobile",
        });
        AOS.refresh();
        $(".menu-fixed").addClass("show-menu");
        $(".menu-fixed").addClass("show-menu-other");
        $(".js-anchor-link").click(function (e) {
          e.preventDefault();
          var target = $($(this).attr("href"));
          if (target.length) {
            var scrollTo = target.offset().top;
            $("body, html").animate({ scrollTop: scrollTo + "px" }, 800);
          }
        });
        var num = 125; //number of pixels before modifying styles
        $(window).bind("scroll", function () {
          if ($(window).scrollTop() > num) {
            $("body").addClass("body-scrolled");
          } else {
            $("body").removeClass("body-scrolled");
          }
        });

        $(".dropdown-menu").removeClass("slider-dropdown");
        $(".menu-side .bar-container").click(function () {
          $(".dropdown-menu").toggleClass("slider-dropdown");
          $(".holder-dropdown").toggleClass("show");
          $(this).toggleClass("open-dropdown");
        });
        $(".sub-arrow-1").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(".menu-primary .sub-pages-1").toggleClass("tap");
        });
        $(".sub-arrow-2").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(".menu-primary .sub-pages-2").toggleClass("tap");
        });
        $(".sub-arrow-3").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(".menu-primary .sub-pages-3").toggleClass("tap");
        });
        $(".sub-arrow-4").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(".menu-primary .sub-pages-4").toggleClass("tap");
        });
        $(".sub-arrow-5").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(".menu-primary .sub-pages-5").toggleClass("tap");
        });
        $(".sub-arrow-6").click(function () {
          $(this).find("i").toggleClass("turn-arrow");
          $(".menu-primary .sub-pages-6").toggleClass("tap");
        });

        // SLICK HEROES
        $(".js-slick-heroes").slick({
          dots: true,
          infinite: true,
          arrows: false,
          // prevArrow: $('.js-hero-prev'),
          // nextArrow: $('.js-hero-next'),
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true,
          draggable: false,
          slidesToShow: 1,
          adaptiveHeight: true,
          pauseOnHover: true,
          customPaging: function (slider, i) {
            // var title = $(slider.$slides[i]).data('title');
            return "";
          },
        });

        $(".js-loadmore-posts").click(function () {
          var button = $(this),
            data = {
              action: "loadmore",
              query: loadmore_posts_params.posts, // that's how we get params from wp_localize_script() function
              page: loadmore_posts_params.current_page,
            };

          $.ajax({
            url: loadmore_posts_params.ajaxurl, // AJAX handler
            data: data,
            type: "POST",
            beforeSend: function (xhr) {
              button.html('<span class="button primary">Loading...</span>'); // change the button text, you can also add a preloader image
            },
            success: function (data) {
              if (data) {
                button
                  .html('<span class="button primary">More posts</span>')
                  .before(data); // insert new posts
                loadmore_posts_params.current_page++;

                if (
                  loadmore_posts_params.current_page ===
                  loadmore_posts_params.max_page
                ) {
                  button.remove(); // if last page, remove the button
                }

                // you can also fire the "post-load" event here if you use a plugin that requires it
                // $( document.body ).trigger( 'post-load' );
              } else {
                button.remove(); // if no data, remove the button as well
              }
            },
          });
        });

        $(document).on("click", 'a[rel="external"]', function (e) {
          e.preventDefault();
          // e.stopPropagation();
          window.open(this.href, "_blank");
        });

        $(".js-select2").select2({
          minimumResultsForSearch: Infinity,
        });

        $(".js-select2-search").select2();

        $(".js-selector-with-url").change(function () {
          location.href = $(this).val();
        });

        $(".js-browse-happy-close").click(function (e) {
          e.preventDefault();
          var $this = $(this);
          $this.parent().hide();
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var $body = $("body");

        //Toggle Filter Options
        $(".js-filter-expand").click(function () {
          $(".js-filter-expander").toggleClass("expanded");
        });

        //Toggle menu
        $(".js-menu-open").click(function () {
          $("body").toggleClass("menu-off-canvas-active");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        $(".js-menu-open-left").click(function () {
          $body.toggleClass("menu-off-canvas-active");
          $body.toggleClass("left");
          $body.removeClass("fb-messenger-active");
          $body.removeClass("popup-active-feedback");
        });

        //Toggle Feedback
        $(".js-popup-feedback").click(function () {
          $body.toggleClass("popup-active-feedback");
          $body.removeClass("fb-messenger-active");
        });

        //Toggle FB Messenger
        $(".js-fb-messenger").click(function () {
          $body.toggleClass("fb-messenger-active");
          $body.removeClass("menu-off-canvas-active");
        });

        //Toggle Search
        $(".js-popup-search").click(function (e) {
          e.preventDefault();
          $(".popup-wrap-search input").focus();
          $body.toggleClass("popup-active-search");
        });

        $(document).keyup(function (e) {
          if (e.keyCode === 27) {
            // escape key maps to keycode `27`
            $body.removeClass(
              "body-is-scrolled menu-off-canvas-active popup-active-search fb-messenger-active popup-active-feedback left"
            );
          }
        });

        //Toggle accordion links
        $(document).on("click", ".js-accordion-title", function (e) {
          e.preventDefault();
          $(this)
            .closest(".js-accordion")
            .toggleClass("expanded")
            .siblings()
            .removeClass("expanded");
        });

        $(".js-popup-feedback-form").submit(function (e) {
          e.preventDefault();

          var $this = $(this);
          var $message = $this.find(".js-message");

          $.ajax({
            type: "get",
            url: templateJS.ajaxURL,
            dataType: "json",
            data: {
              action: "popup_feedback_submit",
              data: $this.serialize(),
            },
            success: function (r) {
              if (r.success) {
                $this.find("input,textarea,button").prop("disabled", true);
                $message.text(
                  "Successful. This popup will automatically close in 5 seconds."
                );
                setTimeout(function () {
                  $body.toggleClass("popup-active-feedback");
                  $this.find("input,textarea,button").prop("disabled", false);
                  $this.find("input,textarea").val("");
                  $message.text(
                    "Please fill out the form below to leave feedback."
                  );
                }, 5000);
              } else {
                $message.hide("slow", function () {
                  $message.text(r.data.message);
                  $message.show("slow");
                });
              }
            },
          });
        });
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
        $(".wrapper").addClass("no-wrapper");
        $("#Stroke-1").addClass("add-dash");
        $("#Stroke-3").addClass("add-dash-other");
        window.setTimeout(function () {
          $("#Stroke-1").addClass("dash-color");
          $("#Stroke-3").addClass("dash-color");
        }, 4000); //<-- Delay in milliseconds
        window.setTimeout(function () {
          $(".homepage .info").addClass("show");
          $(".homepage .info h1").addClass("show");
        }, 3500); //<-- Delay in milliseconds
        window.setTimeout(function () {
          $(".homepage .info p").addClass("show");
        }, 3700); //<-- Delay in milliseconds
        window.setTimeout(function () {
          $(".homepage .info a").addClass("show");
        }, 4000); //<-- Delay in milliseconds
        $(".homepage svg path").addClass("show");
        $(".homepage .africa-circle").addClass("show");
        $(".homepage .overlay").addClass("hide-overlay");
        window.setTimeout(function () {
          $("#beerVideo").removeClass("full-video");
        }, 3500);
        $("#home-button").click(function () {
          $("body").css("overflow", "auto");
          $(".wrapper").css("overflow", "auto");
          $(".homepage").addClass("hidden-box");
          $(".home-video").addClass("hidden-box");
          $(".home-video-mobile").addClass("hidden-box");
          $(".landing").addClass("show-box");
          $(".menu-fixed").addClass("show-menu");

          $("html, body").animate(
            {
              scrollTop: $(".landing").offset().top,
            },
            1500
          );
        });
        // $("#video-play").click(function() {
        //   $("#beerVideo").addClass("full-video");
        //   $(".homepage").addClass("play-video");
        // });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    partners: {
      init: function () {
        // JavaScript to be fired on the about us page
        $(".js-slick-testimonials").slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 5000,
          easing: "swing",
          // dots: false,
          // arrows: false,
          prevArrow:
            '<span class="slick-prev"><i class="fa fa-angle-left"></i></span>',
          nextArrow:
            '<span class="slick-next"><i class="fa fa-angle-right"></i></span>',
          centerMode: false,
          draggable: false,
          focusOnSelect: false,
          adaptiveHeight: true,
          pauseOnHover: true,
          infinite: true,
          dots: false,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 1000,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 300,
              settings: {
                slidesToShow: 1,
              },
            },
          ],
        });
      },
    },
    un_sdgs: {
      init: function () {
        // JavaScript to be fired on the about us page
        var modal = document.getElementsByClassName("popup_button")[0];

        // Get the button that opens the modal
        var btn = document.getElementById("myBtn");

        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];

        // When the user clicks on the button, open the modal

        $(".popup_button").click(function () {
          $(this)
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .parent()
            .next()
            .css("display", "flex");
          $("body").css("overflow", "hidden");
        });

        $(".close").click(function () {
          $(this).parent().parent().css("display", "none");
          $("body").css("overflow", "auto");
        });

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
          if (event.target === modal) {
            modal.style.display = "none";
          }
        };
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
